import { Colors } from "./colors";
import color from "color";

export const mainPalette = {
    primary: {
        light: color(Colors.Orange).lighten(0.5).toString(),
        main: Colors.Orange,
        dark: color(Colors.Orange).darken(0.5).toString(),
    },
    secondary: {
        light: color(Colors.Purple).lighten(0.5).toString(),
        main: Colors.Purple,
        dark: Colors.NavyBlue,
    },
    background: {
        default: Colors.White,
    },
};

export const fleetManagerPalette = {
    primary: {
        light: Colors.Cream,
        main: Colors.Orange,
        dark: Colors.LightOrange,
    },
    secondary: {
        light: Colors.NavyBlue,
        main: Colors.Purple,
        dark: Colors.Rose,
    },
};

export const driverPalette = {
    primary: {
        light: Colors.NavyBlue,
        main: Colors.Purple,
        dark: Colors.Rose,
    },
    secondary: {
        light: Colors.Cream,
        main: Colors.Orange,
        dark: Colors.LightOrange,
    },
};

export const darkPalette = {
    type: "dark",
    primary: {
        main: Colors.White,
    },
    background: {
        default: Colors.LinearGradient,
    },
};
