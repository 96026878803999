import {
    createTheme,
    responsiveFontSizes,
    ThemeOptions,
} from "@material-ui/core";
import {
    mainPalette,
    fleetManagerPalette,
    driverPalette,
    darkPalette,
} from "./palettes";
import {
    OgilvieFont,
    mainTypography,
    fleetManagerTypography,
    driverTypography,
    darkTypography,
    smallerTypography,
} from "./typography";

// These are the defaults, but defined here to allow safely referencing within overrides below:
const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
    },
};

const spacing = 8;

const shape = {
    borderRadius: 6,
};

const overrides = {
    MuiButton: {
        root: {
            fontFamily: OgilvieFont.SemiBold,
        },
        text: {
            fontFamily: OgilvieFont.Bold,
        },
    },
};

const mainOptions = {
    breakpoints,
    palette: mainPalette,
    spacing,
    shape,
    typography: mainTypography,
    overrides,
};

const fleetManagerOptions = {
    breakpoints,
    palette: fleetManagerPalette,
    spacing,
    shape,
    typography: fleetManagerTypography,
    overrides,
};

const driverOptions = {
    breakpoints,
    palette: driverPalette,
    spacing,
    shape,
    typography: driverTypography,
    overrides,
};

const darkOptions = {
    breakpoints,
    palette: darkPalette,
    spacing,
    shape,
    typography: darkTypography,
    overrides,
};

const smallerTypographyOptions = {
    breakpoints,
    palette: mainPalette,
    spacing,
    shape,
    typography: smallerTypography,
    overrides,
};

export const theme = responsiveFontSizes(
    createTheme(mainOptions as ThemeOptions),
);

export const fleetManagerTheme = responsiveFontSizes(
    createTheme(fleetManagerOptions as ThemeOptions),
);

export const driverTheme = responsiveFontSizes(
    createTheme(driverOptions as ThemeOptions),
);

export const darkTheme = responsiveFontSizes(
    createTheme(darkOptions as ThemeOptions),
);

export const smallerTheme = responsiveFontSizes(
    createTheme(smallerTypographyOptions as ThemeOptions),
);
