import { Colors } from "./colors";

export enum OgilvieFont {
    Light = "Ogilvie Light, sans-serif",
    Regular = "Ogilvie Regular, sans-serif",
    SemiBold = "Ogilvie SemiBold, sans-serif",
    Bold = "Ogilvie Bold, sans-serif",
}

export const mainTypography = {
    fontFamily: OgilvieFont.Regular,

    h1: {
        fontFamily: OgilvieFont.Light,
        fontSize: "4.2rem",
        textAlign: "left",
        paddingBottom: "1rem",
        fontVariantLigatures: "no-common-ligatures",
        color: Colors.Purple,
        backgroundColor: Colors.Rose,
        backgroundImage: Colors.LinearGradient,
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        MozBackgroundClip: "text",
        MozTextFillColor: "transparent",
        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)":
            {
                backgroundColor: Colors.Transparent,
                backgroundImage: "none",
            },
        textWrap: "balance",
    },

    h2: {
        fontFamily: OgilvieFont.Light,
        fontSize: "3rem",
        textAlign: "left",
        paddingBottom: "0.8rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    h3: {
        fontFamily: OgilvieFont.Light,
        fontSize: "2.2rem",
        textAlign: "left",
        paddingBottom: "0.5rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    h4: {
        fontFamily: OgilvieFont.Light,
        fontSize: "1.8rem",
        textAlign: "left",
        paddingBottom: "0.3rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    body1: {
        fontSize: "1.4rem",
        textAlign: "left",
        paddingBottom: "0.2rem",
        fontVariantLigatures: "no-common-ligatures",
    },

    body2: {
        fontSize: "1.3rem",
        textAlign: "left",
        paddingBottom: "0.1rem",
        fontVariantLigatures: "no-common-ligatures",
    },
};

export const fleetManagerTypography = {
    fontFamily: OgilvieFont.Regular,

    h1: {
        fontFamily: OgilvieFont.Light,
        fontSize: "4.2rem",
        textAlign: "left",
        color: Colors.Orange,
        paddingBottom: "1rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    h2: {
        fontFamily: OgilvieFont.Light,
        fontSize: "3rem",
        textAlign: "left",
        color: Colors.Orange,
        paddingBottom: "0.8rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    h3: {
        fontFamily: OgilvieFont.Light,
        fontSize: "2.2rem",
        textAlign: "left",
        color: Colors.Orange,
        paddingBottom: "0.5rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    h4: {
        fontFamily: OgilvieFont.Light,
        fontSize: "1.8rem",
        textAlign: "left",
        paddingBottom: "0.3rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    body1: {
        fontSize: "1.4rem",
        textAlign: "left",
        paddingBottom: "0.2rem",
        fontVariantLigatures: "no-common-ligatures",
    },

    body2: {
        fontSize: "1.3rem",
        textAlign: "left",
        paddingBottom: "0.1rem",
        fontVariantLigatures: "no-common-ligatures",
    },
};

export const driverTypography = {
    fontFamily: OgilvieFont.Regular,

    h1: {
        fontFamily: OgilvieFont.Light,
        fontSize: "4.2rem",
        textAlign: "left",
        color: Colors.Purple,
        paddingBottom: "1rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    h2: {
        fontFamily: OgilvieFont.Light,
        fontSize: "3rem",
        textAlign: "left",
        color: Colors.Purple,
        paddingBottom: "0.8rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    h3: {
        fontFamily: OgilvieFont.Light,
        fontSize: "2.2rem",
        textAlign: "left",
        color: Colors.Purple,
        paddingBottom: "0.5rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    h4: {
        fontFamily: OgilvieFont.Light,
        fontSize: "1.8rem",
        textAlign: "left",
        paddingBottom: "0.3rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    body1: {
        fontSize: "1.4rem",
        textAlign: "left",
        paddingBottom: "0.2rem",
        fontVariantLigatures: "no-common-ligatures",
    },

    body2: {
        fontSize: "1.3rem",
        textAlign: "left",
        paddingBottom: "0.1rem",
        fontVariantLigatures: "no-common-ligatures",
    },
};

export const darkTypography = {
    fontFamily: OgilvieFont.Regular,

    h1: {
        fontFamily: OgilvieFont.Light,
        fontSize: "4.2rem",
        textAlign: "left",
        color: Colors.White,
        paddingBottom: "1rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    h2: {
        fontFamily: OgilvieFont.Light,
        fontSize: "3rem",
        textAlign: "left",
        color: Colors.White,
        paddingBottom: "0.8rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    h3: {
        fontFamily: OgilvieFont.Light,
        fontSize: "2.2rem",
        textAlign: "left",
        color: Colors.White,
        paddingBottom: "0.5rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    h4: {
        fontFamily: OgilvieFont.Light,
        fontSize: "1.8rem",
        textAlign: "left",
        color: Colors.White,
        paddingBottom: "0.3rem",
        fontVariantLigatures: "no-common-ligatures",
        textWrap: "balance",
    },

    body1: {
        fontSize: "1.5rem",
        textAlign: "left",
        color: Colors.White,
        paddingBottom: "0.2rem",
        fontVariantLigatures: "no-common-ligatures",
    },

    body2: {
        fontSize: "1.4rem",
        textAlign: "left",
        color: Colors.White,
        paddingBottom: "0.1rem",
        fontVariantLigatures: "no-common-ligatures",
    },
};

export const smallerTypography = {
    ...mainTypography,

    h1: {
        ...mainTypography.h1,
        fontSize: "3rem",
        paddingBottom: 0,
    },

    h2: {
        ...mainTypography.h2,
        fontSize: "2rem",
        paddingBottom: 0,
    },

    h3: {
        ...mainTypography.h3,
        fontSize: "1.8rem",
        paddingBottom: 0,
    },

    h4: {
        ...mainTypography.h4,
        fontSize: "1.4rem",
        paddingBottom: 0,
    },

    body1: {
        ...mainTypography.body1,
        fontSize: "1rem",
        paddingBottom: 0,
    },

    body2: {
        ...mainTypography.body2,
        fontSize: "0.875rem",
        paddingBottom: 0,
    },
};
